import React from 'react'
import PropTypes from 'prop-types'
import { EachInfoContainer } from '../shared/container'
import { InfoExam } from '../shared/text-styled'

const TussCode = ({ exam }) => {
  return (
    <EachInfoContainer noBorder>
      <InfoExam>{exam.tussCode}</InfoExam>
    </EachInfoContainer>
  )
}

TussCode.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default TussCode
