import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import Accordion from '../shared/Accordion'
import Description from './Description'
import Preparation from './Preparation'
import Documents from './Documents'
import SampleType from './SampleType'
import Methodology from './Methodology'
import TussCode from './TussCode'

const AccordionContent = ({ exam, data }) => {
  const accordionData = data.wordpressWpGatsbyPage.acf
  const examDetails = []

  examDetails.push({
    title: accordionData.texto_de_oque_e_exame,
    content: <Description 
              exam={exam}
              titleSubAccordion={accordionData.texto_de_para_que_serve_exame} 
            />,
    openDesktop: true,
    openMobile: true,
  })

  examDetails.push({
    title: accordionData.texto_de_documentos_necessarios,
    content: <Documents documents={exam.documents} noResults={accordionData.text_no_results} />,
    openDesktop: false,
    openMobile: false,
  })

  if (exam.preparation && exam.preparation.length > 0) {
    examDetails.push({
      title: accordionData.texto_de_preparo,
      content: <Preparation exam={exam} />,
      openDesktop: false,
      openMobile: false,
    })
  }

  if (exam.materials && exam.materials.length > 0) {
    examDetails.push({
      title: accordionData.texto_de_tipo_de_amostra,
      content: <SampleType exam={exam} noResults={accordionData.text_no_results} />,
      openDesktop: false,
      openMobile: false,
    })
  }

  if (exam.informativeProduct && exam.informativeProduct.metodo) {
    examDetails.push({
      title: accordionData.texto_de_metodologia,
      content: <Methodology exam={exam} />,
      openDesktop: false,
      openMobile: false,
    })
  }

  if (exam.tussCode) {
    examDetails.push({
      title: accordionData.texto_de_codigo_tuss,
      content: <TussCode exam={exam} />,
      openDesktop: false,
      openMobile: false,
    })
  }

  const mountExamDetails = details => details.map(obj => ({ ...obj, uuid: v4() }))

  return <Accordion items={mountExamDetails(examDetails)} singleExam />
}

AccordionContent.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default AccordionContent
